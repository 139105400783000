<template>
  <article>
    <RouterNav name="查看年级模考报告"></RouterNav>
    <header class="header">
      <div class="column">
        <div class="left">
          <el-autocomplete
            class="input"
            v-model="searchName"
            :fetch-suggestions="querySearch"
            placeholder="请输入学校名称搜索"
            :trigger-on-focus="false"></el-autocomplete>
          <el-select class="col" v-model="year" placeholder="年度">
            <el-option v-for="(item, index) in yearList" :key="index" :label="item" :value="item"></el-option>
          </el-select>
          <el-select class="col" v-model="gradeId" placeholder="年级">
            <el-option v-for="(item, index) in gradeList" :key="index" :label="item.gradeName" :value="item.gradeTypeId"></el-option>
          </el-select>
          <el-select class="col" v-model="type">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </header>

    <div class="empty-bg" v-if="showCharts">
      <div>
        <div><img class="icon" src="../../../assets/default.png" alt="default"></div>
        <div>请选择年级和年度进行查阅</div>
      </div>
    </div>
    <div v-if="!showCharts">
      <ExamCharts
        :className="this.gradeName"
        ref="examCharts"
      ></ExamCharts>

      <section class="section">
        <div class="title">
          <div class="left">
            <div class="line"></div>
            <div>班级报告</div>
          </div>
          <div class="right" @click="classReportChange">
            <div class="hidden">{{classReportShow ? '点击收起' : '点击查看更多'}}</div>
            <div :class="['btn', classReportShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
        <div v-show="classReportShow">
          <div class="sub">
            <el-tooltip class="item" effect="dark" content="优异班级：得分率≥80%的班级" placement="top-start">
              <div class="sub-title">
                <span>表现优异班级</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="excellentListShowAll ? excellentList : excellentList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classExamReport(scope.row.classId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('excellentListShowAll')" v-if="excellentList.length > 5">
            <div>{{excellentListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[excellentListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-3">
            <el-tooltip class="item" effect="dark" content="需重点关注班级：得分率<60%的班级" placement="top-start">
              <div class="sub-title">
                <span>需重点关注班级</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="monitorListShowAll ? monitorList : monitorList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classExamReport(scope.row.classId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('monitorListShowAll')" v-if="monitorList.length > 5">
            <div>查看更多</div>
            <img :class="[monitorListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-2">
            <el-tooltip class="item" effect="dark" content="默认根据“得分率”的值由高到低排名" placement="top-start">
              <div class="sub-title">
                <span>全部班级列表</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="classListShowAll ? classList : classList.slice(0, 10)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classExamReport(scope.row.classId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('classListShowAll')" v-if="classList.length > 10">
            <div>{{classListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[classListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

        </div>
      </section>

    </div>

  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'
import ExamCharts from '../components/examCharts'
export default {
  name: 'gradeExamReport',
  components: {
    RouterNav,
    ExamCharts
  },
  data () {
    return {
      searchSchoolList: [],
      searchName: '',
      searchId: '',
      yearList: [],
      year: '',
      gradeList: [],
      gradeId: '',
      gradeName: '',
      num: 0,
      typeList: [{
        id: 1,
        name: '听说模考'
      }, {
        id: 2,
        name: '笔试模考'
      }],
      type: 1,
      showCharts: true,
      classReportShow: false,
      excellentList: [],
      excellentListShowAll: false,
      monitorList: [],
      monitorListShowAll: false,
      classList: [],
      classListShowAll: false
    }
  },
  watch: {
    searchName (val) {
      const { searchSchoolList } = this
      if (searchSchoolList.length === 0) return
      for (let i = 0, len = searchSchoolList.length; i < len; i += 1) {
        if (val === searchSchoolList[i].schoolName) {
          this.searchId = searchSchoolList[i].schoolId
          break
        }
      }
    },
    searchId (val) {
      this.year = ''
      this.gradeId = ''
      this.getGradeBySchoolId(val)
    },
    gradeId (val) {
      const { gradeList } = this
      for (let i = 0, len = gradeList.length; i < len; i += 1) {
        if (val === gradeList[i].gradeTypeId) {
          this.gradeName = gradeList[i].gradeName
          break
        }
      }
    }
  },
  created () {
    this.getSchoolList()
  },
  mounted () {
    document.getElementById('app').scrollTop = 0
    this.$store.commit('includeAdd', 'gradeExamReport')
  },
  methods: {
    getSchoolList () {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            const schoolId = sessionStorage.getItem('schoolId')
            for (let i = 0, len = data.length; i < len; i += 1) {
              data[i].value = data[i].schoolName
              if (schoolId === data[i].schoolId) {
                this.searchId = schoolId
                this.searchName = data[i].schoolName
              }
            }
            this.searchSchoolList = data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getGradeBySchoolId (val) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getGradeBySchoolId,
        data: {
          schoolId: val
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            const searchYearList = []
            let searchGradeList = []
            for (let i = 0, len = data.length; i < len; i += 1) {
              if (!searchYearList.includes(data[i].year)) {
                searchYearList.push(data[i].year)
              }
              if (data[i].gradeTypeId) {
                searchGradeList.push(data[i])
              }
            }
            searchGradeList = this.common.uniqueForObj(searchGradeList, 'gradeName')
            searchGradeList.sort((a, b) => {
              return a.gradeTypeId - b.gradeTypeId
            })
            this.gradeList = searchGradeList
            searchYearList.sort()
            this.yearList = searchYearList
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    querySearch (queryString, cb) {
      const { searchSchoolList } = this
      const list = searchSchoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    schoolFilter (name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    search () {
      const {searchId, gradeId, year, type} = this
      if (!searchId) {
        this.$alert('请选择学校', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!gradeId) {
        this.$alert('请选择年级', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!year) {
        this.$alert('请选择年度', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      this.showCharts = false
      this.classReportShow = false
      this.excellentList = []
      this.excellentListShowAll = false
      this.monitorList = []
      this.monitorListShowAll = false
      this.studentList = []
      this.studentListShowAll = false
      this.$nextTick(() => {
        this.$refs.examCharts.getDataViewExam(this.$urls.getDataViewExamSuper, {
          schoolId: searchId,
          gradeId,
          year,
          type
        })
      })
    },
    userExamReport () {
      // TODO 缺少参数带过去
      this.$router.push({
        name: 'userExamReport'
      })
    },
    classReportChange () {
      this.classReportShow = !this.classReportShow
      if (this.classReportShow && this.classList.length === 0) {
        this.getClassReportExam()
      }
    },
    getClassReportExam () {
      this.$axios({
        method: 'post',
        url: this.$urls.getClassReportExam,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          schoolId: this.searchId,
          gradeId: this.gradeId,
          year: this.year,
          type: this.type
        }
      })
        .then((response) => {
          if (response.state === '11') {
            const { data } = response
            this.excellentList = data.excellentList
            this.monitorList = data.monitorList
            this.classList = data.classList
          }
        })
    },
    listShowAllChange (attr) {
      this[attr] = !this[attr]
    },
    classExamReport (id, name) {
      this.$store.commit('includeDel', 'classExamReport')
      this.$router.push({
        name: 'classExamReport',
        query: {
          id,
          name,
          type: this.type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .header {
    background-color: #fff;
    padding: 0 28px;
    margin-bottom: 10px;
    .title {
      height: 84px;
      line-height: 84px;
      @include font(20px, #333);
      font-weight: bold;
    }
    .column {
      @include flex(space-between);
      height: 56px;
      font-size: 15px;
      .left {
        display: flex;
        align-items: center;
      }
      .col {
        width: 140px;
        margin-right: 10px;
      }
      .input {
        width: 250px;
        margin-right: 10px;
      }
    }
  }
  .empty-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: calc(100vh - 194px);
    font-size: 14px;
    color: #999999;
    text-align: center;
    .icon {
      width: 90px;
      height: 103px;
      margin-bottom: 14px;
    }
  }
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .input {
        width: 250px;
        margin-right: 10px;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        transition: all .3s;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .charts {
      margin: 0 28px 20px;
      border: 1px solid #E5E5E5;
      .radio {
        padding: 12px 14px 0;
      }
    }
    .wrapper {
      display: flex;
      overflow: auto;
      padding: 0 28px 20px;
      .list {
        flex-shrink: 0;
        width: 522px;
        border: 1px solid #E5E5E5;
      }
    }
    .sub {
      border-top: 2px solid #1DC060;
      .sub-title {
        display: inline-block;
        background: linear-gradient(to right, #1DC060, #3EE199);
        height: 28px;
        line-height: 28px;
        margin-left: 28px;
        padding: 0 14px;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 14px;
        .mark {
          width: 14px;
          height: 14px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .sub-title-default {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
      .sub-title-warn {
        background: linear-gradient(to right, #FF6F5E, #FF6F5E);
      }
    }
    .sub-2 {
      border-top: 2px solid #309AF2;
      .sub-title {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
    }
    .sub-3 {
      border-top: 2px solid #FF3C30;
      .sub-title {
        background: linear-gradient(to right, #FF3C30, #FF6F5E);
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
        transition: all .3s;
      }
    }
  }
</style>
